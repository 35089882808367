import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'nl'];

i18n
    //.use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // fallback language is english.

        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
        resources: {
            en: {
                translation: {
                    personalFields: 'Personal Field',
                    addressFields: 'Address Information',
                    firstName: 'First name',
                    lastName: 'Last name',
                    phone: 'Phonenumber',
                    email: 'E-mail',
                    dob: 'Date of Birth',
                    address: 'Address',
                    zipCode: 'Postcode',
                    city: 'City',
                    country: 'Country',
                    courseDate:'Course starting date',
                    chooseDate:'Choose your starting date',
                    recaptchaError: 'Please click the I\'m not a robot button',
                    paymentInfo: 'You will receive a payment  request by  email after booking',
                    book:'book',
                    course:'course',
                    exam:'exam',
                    total:'total',
                    nextButton:'Next',
                    prevButton:'Previous',
                    yourOrder:'Your order',
                    lpePlacementTest:'LPE Placement Test',
                    lpePlacementTestDescr:'The LPE placement test is a flexible test of English language proficiency and takes you about 20 minutes to complete.\n' +
                        '\n' +
                        'The score of this internet based test gives you an indication whether you need one of our training products or not. If the test indicates that no training is needed, you are ready to do the LPE test.',
                    lpePrepCourse:'LPE Preparation Course',
                    lpePrepCourseDescr:'Duration of the course is 90 minutes and after completing you are well prepared and ready to reach your highest possible ICAO score.',
                    lpeExamDescr:'The test is fully online and can be done any time. You need a computer with internet + a webcam and a microphone',
                    lpeExam:'LPE Exam'

                }
            },
            nl: {
                translation: {
                    personalFields: 'Persoonlijke informatie',
                    addressFields: 'Adres informatie',
                    firstName: 'Voornaam',
                    lastName: 'Achternaam',
                    email: 'E-mail',
                    phone: 'Telefoonnummer',
                    dob: 'Geboortedatum',
                    address: 'Adres',
                    zipCode: 'Postcode',
                    city: 'Woonplaats',
                    country: 'Land',
                    courseDate:'Cursus start datum',
                    chooseDate:'Kies de startdatum van je cursus',
                    recaptchaError:'Klik a.u.b. op Ik ben geen robot',
                    paymentInfo: 'Je ontvang later een betaalverzoek per email',
                    book:'boek',
                    course:'cursus',
                    exam:'examen',
                    total:'totaal',
                    nextButton:'Volgende',
                    prevButton:'Terug',
                    yourOrder:'Je bestelling',
                    lpePlacementTest:'LPE Placement Test',
                    lpePlacementTestDescr:'De LPE placement test is een flexibele test die een indicatie geeft van het niveau van de Engelse taal. De test duurt 20 minuten en is vanaf jouw pc te maken. De score van deze test geeft aan of je aanvullende training nodig hebt of dat je reeds op het niveau zit om het LPE examen te doen.',
                    lpePrepCourse:'LPE Voorbereidings Cursus',
                    lpePrepCourseDescr:'De cursus duurt 90 minuten en na afloop heb je de ideale voorbereiding op het LPE en ben je in staat om een zo hoog mogelijke score te behalen.\n' +
                        '\n',
                    lpeExamDescr:'Het LPE kost €164,- en vindt online plaats op een zelf te bepalen tijdstip. Je hebt een computer nodig die aangesloten is op het internet + een webcam en een microfoon',
                    lpeExam:'LPE Examen'


                }
            }
        }
    });

export default i18n;